import React, { useState, useEffect, useContext } from "react";
import Header from '../components/Header';
import UserContext from "../context/useLoginContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loading from '../components/Loading';
import Favorites from "./Favorites";
import FiruBox from "./FiruBox";
import Profile from "./Profile";
import Services from "./Services";
import NewService from "./NewService";
import '@styles/Dashboard.scss';

const Dashboard = () => {
    const location = useLocation();
    const { user } = useContext(UserContext);

    const navigate = useNavigate();
    const { page } = useParams();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        if (page) {
            if (
                    page !== 'profile' && page !== 'favorites' && page !== 'services' && page !== 'firubox' &&
                    page !== 'new-service' && !Number.isInteger(parseInt(page.match(/\d+/)))) {
                navigate('/');
            }
            setLoading(false)
        }
    };

    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    console.log("user = ", user)

    return (
        <div className="Dashboard">
            <header className="header"><Header /></header>
            <div className='Content'>
                <div className="Menu">
                    {user && <p className={page === "profile" ? "item-menu font-bold" : "item-menu"} onClick={() => navigate("/dashboard/profile")}>Mi cuenta</p>}
                    <p className={page === "firubox" ? "item-menu font-bold" : "item-menu"} onClick={() => navigate("/dashboard/firubox")}>FiruBox</p>
                    {user && <p className={page === "favorites" ? "item-menu font-bold" : "item-menu"} onClick={() => navigate("/dashboard/favorites")}>Mis favoritos</p>}
                    {user && <p className={page === "services" ? "item-menu font-bold" : "item-menu"} onClick={() => navigate("/dashboard/services")}>Mis servicios</p>}
                </div>
                <div className="container">
                    {
                        page === 'favorites' && user ? <Favorites/> :
                        page === 'firubox' ? <FiruBox/> :
                        page === 'profile' && user ? <Profile/> :
                        page === 'services' && user ? <Services/> :
                        page === 'new-service' && user ? <NewService/> : <NewService storeId={page.match(/\d+/)}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
